import React, { Component } from 'react'
import axios from 'axios'
import './styles/resources.css'

class Resources extends Component {

  state = {
    pageTitle: 'Installation Guidelines',
    pageContent: "We're committed to making sure Signature flooring deliver maximum performance and aesthetics for every space. That means sticking to rigorous design, engineering, materials and manufacturing standards. It also means providing you with the proper installation guidelines for ensuring your flooring's long-term performance. ",
    resourceNavLinkClass: ['','active-link','','','','',''],
    resources: [],
    installations: [],
    sundries: [],
    maintenance: [],
    warranties: [],
    specification: [],
    specifications: [],
    antimicrobial: [],
    cads: [],
    resourceType: this.props.match.params.resourcetype
  }

  componentDidMount() {
    axios.get('/wp-json/wp/v2/resource-docs/?per_page=100&filter[orderby]=title&order=asc')
      .then(response => {
        this.setState({
          resources: response.data,
          //installations: response.data.filter(item => item.acf.resource_type === 'Installation'),
          //sundries: response.data.filter(item => item.acf.resource_type === 'Sundries'),
          //maintenance: response.data.filter(item => item.acf.resource_type === 'Maintenance'),
          //warranties: response.data.filter(item => item.acf.resource_type === 'Warranties'),
          //specifications: response.data.filter(item => item.acf.resource_type === 'Specification'),
        }, () => {
          switch(this.state.resourceType) {
            case 'specs':
              this.showHide(0)
              break
            case 'installation':
              this.showHide(1)
              break
            case 'sundries':
              this.showHide(2)
              break
            case 'maintenance-resources':
              this.showHide(3)
              break
            case 'warranties':
              this.showHide(4)
              break
            case 'antimicrobial':
              this.showHide(5)
              break
            case 'cads':
              this.showHide(6)
              break
            default:
              this.showHide(1)
          }
        })
        
      }).then(() => {
        axios.get('/wp-json/wp/v2/resource-docs/?filter[meta_query]=resource_type&filter[meta_value]=Maintenance&filter[orderby]=title&order=asc&per_page=100')
          .then(response => {
            this.setState({
              maintenance: response.data,
            })
          })
      }).then(() => {
        axios.get('/wp-json/wp/v2/resource-docs/?filter[meta_query]=resource_type&filter[meta_value]=Warranties&filter[orderby]=title&order=asc&per_page=100')
          .then(response => {
            this.setState({
              warranties: response.data,
            })
          })
      }).then(() => {
        axios.get('/wp-json/wp/v2/resource-docs/?filter[meta_query]=resource_type&filter[meta_value]=Installation&filter[orderby]=title&order=asc&per_page=100')
          .then(response => {
            this.setState({
              installations: response.data,
            })
          })
      })
      .then(() => {
        axios.get('/wp-json/wp/v2/resource-docs/?filter[meta_query]=resource_type&filter[meta_value]=Sundries&filter[orderby]=title&order=asc&per_page=100')
          .then(response => {
            this.setState({
              sundries: response.data,
            })
          })
      }).then(() => {
        axios.get('/wp-json/wp/v2/resource-docs/?filter[meta_query]=resource_type&filter[meta_value]=Antimicrobial&filter[orderby]=title&order=asc&per_page=100')
          .then(response => {
            this.setState({
              antimicrobial: response.data,
            })
          })
      }).then(() => {
        axios.get('/wp-json/wp/v2/resource-docs/?filter[meta_query]=resource_type&filter[meta_value]=Specification&filter[orderby]=title&order=asc&per_page=100')
          .then(response => {
            this.setState({
              specifications: response.data,
            })
          })
      })
      .catch(errors => console.log(errors))
  }

  showHide(num) {
    if (num === 0) {
      this.setState({
        pageTitle: 'Specifications',
        resourceNavLinkClass: ['active-link','','','','','',''],
        pageContent: "We're committed to making sure Signature flooring delivers maximum performance and aesthetics for every space. That means sticking to rigorous design, engineering, materials and manufacturing standards. It also means providing you with the proper specifications for ensuring your project selection."
      })
    }
    if (num === 1) {
      this.setState({
        pageTitle: 'Installation Guidelines',
        resourceNavLinkClass: ['','active-link','','','','',''],
        pageContent: "We're committed to making sure Signature flooring delivers maximum performance and aesthetics for every space. That means sticking to rigorous design, engineering, materials and manufacturing standards. It also means providing you with the proper installation guidelines for ensuring your flooring's long-term performance."
      })
    }
    if (num === 2) {
      this.setState({
        pageTitle: 'Sundries',
        resourceNavLinkClass: ['','','active-link','','','',''],
        pageContent: "We're committed to making sure Signature flooring delivers maximum performance and aesthetics for every space. That means sticking to rigorous design, engineering, materials and manufacturing standards. It also means providing you with the proper sundries for ensuring your flooring's installation and long-term performance is met."
        
      })
    }
    if (num === 3) {
      this.setState({
        pageTitle: 'Care & Maintenance',
        resourceNavLinkClass: ['','','','active-link','','',''],
        pageContent: "We're committed to making sure Signature flooring deliver maximum performance and aesthetics for every space. That means sticking to rigorous design, engineering, materials and manufacturing standards. It also means providing you with the proper care and maintenance guidelines for ensuring your flooring's long-term performance."
      })
    }
    if (num === 4) {
      this.setState({
        pageTitle: 'Warranties',
        resourceNavLinkClass: ['','','','','active-link','',''],
        pageContent: "We're committed to making sure Signature flooring delivers maximum performance and aesthetics for every space. That means sticking to rigorous design, engineering, materials and manufacturing standards. It also means providing you with the proper warranites for ensuring your flooring's long-term performance."
      })

    }

    if (num === 5) {
      this.setState({
        pageTitle: 'Antimicrobial',
        resourceNavLinkClass: ['','','','','active-link',''],
        pageContent: "We're committed to making sure Signature flooring delivers maximum performance and aesthetics for every space. That means sticking to rigorous design, engineering, materials and manufacturing standards."
      })
    }

    if (num === 6) {
      this.setState({
        pageTitle: 'CADS',
        resourceNavLinkClass: ['','','','','','','active-link'],
        pageContent: "We're committed to making sure Signature flooring delivers maximum performance and aesthetics for every space. That means sticking to rigorous design, engineering, materials and manufacturing standards."
      })
    }
  }

  render() {
    const { pageTitle, pageContent, installations, sundries, maintenance, warranties, specifications, antimicrobial } = this.state
    return (
      <div>
        {/** HEADER */}
        <div className="resources-header">
          <div className="resources-header-content">
            <h2>Resources</h2>
            <nav className="desktop-nav">
              <ul>
              <li><a href="##" onClick={() => this.showHide(6)} className={this.state.resourceNavLinkClass[6]}>CADS</a></li>
                <li><a href="##" onClick={() => this.showHide(0)} className={this.state.resourceNavLinkClass[0]}>Specifications</a></li>
                <li><a href="##" onClick={() => this.showHide(1)} className={this.state.resourceNavLinkClass[1]}>Installation</a></li>
                <li><a href="##" onClick={() => this.showHide(2)} className={this.state.resourceNavLinkClass[2]}>Sundries</a></li>
                <li><a href="##" onClick={() => this.showHide(3)} className={this.state.resourceNavLinkClass[3]}>Care & Maintenance</a></li>
                <li><a href="##" onClick={() => this.showHide(4)} className={this.state.resourceNavLinkClass[4]}>Warranties</a></li>
                <li><a href="##" onClick={() => this.showHide(5)} className={this.state.resourceNavLinkClass[5]}>Antimicrobial</a></li>
              </ul>
            </nav>
            <nav className="mobile-nav" style={{ marginLeft: '-47px', paddingTop: '35px', bottom: '0'}}>
              <ul>
              <li><a href="##" onClick={() => this.showHide(6)} className={this.state.resourceNavLinkClass[6]}>CADS</a></li>
                <li><a href="##" onClick={() => this.showHide(0)} className={this.state.resourceNavLinkClass[0]}>Specs</a></li>
                <li><a href="##" onClick={() => this.showHide(1)} className={this.state.resourceNavLinkClass[1]}>Installation</a></li>
                <li><a href="##" onClick={() => this.showHide(2)} className={this.state.resourceNavLinkClass[2]}>Sundries</a></li>
                <li><a href="##" onClick={() => this.showHide(3)} className={this.state.resourceNavLinkClass[3]}>Care & Maint.</a></li>
                <li><a href="##" onClick={() => this.showHide(4)} className={this.state.resourceNavLinkClass[4]}>Warranties</a></li>
                <li><a href="##" onClick={() => this.showHide(5)} className={this.state.resourceNavLinkClass[5]}>Antimicrobial</a></li>
              </ul>
            </nav>
          </div>
        </div>
        {/** CONTENT */}
        <div className="resources-main-content">
          <h2>{ pageTitle }</h2>
          <p>{pageContent}</p>
          { pageTitle === 'Specifications' &&
            <div className="resources-wrapper">
              <div className="resources-col-1">
              <span className="product-title">Carpet</span>
                <div className="spec-col-1">
                <div className="spec-col">
                  <div className="resources-col-header">
                    Broadloom
                  </div>
                  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'Broadloom').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
                </div>

                <div className="spec-col">				  
                  <div className="resources-col-header">
                    Carpet Tile
                  </div>
                  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'Carpet Tile').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
                  <br/>
				  <div className="resources-col-header">
                    Carpet Pad
                  </div>
                  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'Carpet Pad').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
                  <br/>
                  <div className="resources-col-header">
                    Entryway System
                  </div>
                  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'Entryway').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
                  <br/>
                  <div className="resources-col-header">
                    SignatureNOW
                  </div>
				  
				  {/*
                  <div className="resources-col-links">
                    <div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/Dash-B7001-Broadloom.pdf" target="_new" rel="noopener noreferrer">Dash</a></div>
                    <div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/Savor-B7002-Broadloom.pdf" target="_new" rel="noopener noreferrer">Savor</a></div>
                    <div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/Vibe-B7003-Broadloom.pdf" target="_new" rel="noopener noreferrer">Vibe</a></div>
                    <div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/Zeal-B7004-Broadloom.pdf" target="_new" rel="noopener noreferrer">Zeal</a></div>
                    <div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/Allure-B7007-Broadloom.pdf" target="_new" rel="noopener noreferrer">Allure</a></div>
                    <div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/Dazzle-B7006-Broadloom.pdf" target="_new" rel="noopener noreferrer">Dazzle</a></div>
                    <div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/Pizzaz-B7005-Broadloom.pdf" target="_new" rel="noopener noreferrer">Pizazz</a></div>
										<div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7035-WICK.pdf" target="_new" rel="noopener noreferrer">Wick</a></div>
										<div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7036-TRAVEN.pdf" target="_new" rel="noopener noreferrer">Traven</a></div>
										<div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7037-FALCO.pdf" target="_new" rel="noopener noreferrer">Falco</a></div>
										<div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7038-LOMAX.pdf" target="_new" rel="noopener noreferrer">Lomax</a></div>
										<div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7039-KAI.pdf" target="_new" rel="noopener noreferrer">Kai</a></div>
										<div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7040-UTAH.pdf" target="_new" rel="noopener noreferrer">Utah</a></div>
										<div style={{paddingTop: '5px', paddingBottom: '5px'}}><a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7041-NEO.pdf" target="_new" rel="noopener noreferrer">Neo</a></div>
                  </div>
				  */}
				  
				  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'SignatureNOW').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
				  
                </div>

                
              </div>
              </div>

              <div className="resources-col-2">
              <span className="product-title">Hard Surface</span>
              <div className="spec-col-1">
							
                <div className="spec-col">
                  <div className="resources-col-header">
                    WPC
                  </div>
                  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'WPC').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
                </div>
								
								<div className="spec-col">
                  <div className="resources-col-header">
                    SPC
                  </div>
                  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'SPC').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
                </div>

                <div className="spec-col">
                  <div className="resources-col-header">
                    LVT
                  </div>
                  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'LVT').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
                </div>
								
								<div className="spec-col">
                  <div className="resources-col-header">
                    Loose Lay
                  </div>
                  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'Loose Lay').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
                </div>
				
				<div className="spec-col">
                  <div className="resources-col-header">
                    Rubber Wall Base
                  </div>
                  <div className="resources-col-links">
                    {specifications.filter(item => item.acf.sub_category === 'Rubber Wall Base').map((spec) => (
                      <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div>
                    ))}
                  </div>
                </div>
								
              </div>
            </div>
          </div>
          }
          { pageTitle === 'Installation Guidelines' &&
            <div className="resources-wrapper">
            <div className="resources-col-1">
                <div className="resources-col-header">
                  Carpet
                </div>
                <div className="resources-col-links">
                  {installations.filter(item => item.acf.resource_category === 'Carpet').map((carpet) => (
                    <div key={carpet.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={carpet.acf.resource_file.url} target="_new">{carpet.title.rendered}</a></div>
                  ))}
                </div>

                <div className="resources-col-header">
                  Entryway System
                </div>
                <div className="resources-col-links">
                  {installations.filter(item => item.acf.resource_category === 'Entryway').map((carpet) => (
                    <div key={carpet.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={carpet.acf.resource_file.url} target="_new">{carpet.title.rendered}</a></div>
                  ))}
                </div>
              </div>

              <div className="resources-col-2">
                <div className="resources-col-header">
                  Hard Surface
                </div>
                <div className="resources-col-links">
                {installations.filter(item => item.acf.resource_category === 'Hard Surfaces').map((hs) => (
                    <div key={hs.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={hs.acf.resource_file.url} target="_new">{hs.title.rendered}</a></div>
                  ))}
                </div>
            </div>
          </div>
          }

          { pageTitle === 'Sundries' &&
            <div>
            <div className="resources-wrapper">
            <div className="resources-col-1">
            <span className="product-title">Carpet</span>
              <div className="spec-col-1">
              <div className="spec-col">
                <div className="resources-col-header">
                  Adhesive
                </div>
                <div className="resources-col-links">
                {sundries.filter(item => item.acf.sub_category === 'Broadloom').map((spec => {
                    return spec.acf.resource_category === 'Adhesive' ? <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div> : ''                 
                  }))}
                  {sundries.filter(item => item.acf.sub_category === 'Carpet Tile').map((spec => {
                    return spec.acf.resource_category === 'Adhesive' ? <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div> : ''                 
                  }))}
                </div>
              </div>
			  
			  <div className="spec-col">
                <div className="resources-col-header">
                  Carpet Pad
                </div>
                <div className="resources-col-links">
                {sundries.filter(item => item.acf.sub_category === 'Carpet Pad').map((spec => {
                    return spec.acf.resource_category === 'Carpet Pad' ? <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div> : ''                 
                  }))}
                </div>
              </div>

              <div className="spec-col">
                <div className="resources-col-header">
                  Sealer
                </div>
                <div className="resources-col-links">
                {sundries.filter(item => item.acf.sub_category === 'Broadloom').map((spec => {
                    return spec.acf.resource_category === 'Sealer' ? <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div> : ''                 
                  }))}
                  {sundries.filter(item => item.acf.sub_category === 'Carpet Tile').map((spec => {
                    return spec.acf.resource_category === 'Sealer' ? <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div> : ''                 
                  }))}
                </div>
              </div>

              
            </div>
            </div>

            <div className="resources-col-2">
            <span className="product-title">Hard Surface</span>
            <div className="spec-col-1">
              <div className="spec-col">
                <div className="resources-col-header">
                  Adhesive
                </div>
                <div className="resources-col-links">
                  {sundries.filter(item => item.acf.sub_category === 'WPC').map((spec => {
                    return spec.acf.resource_category === 'Adhesive' ? <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div> : ''                 
                  }))}
                  {sundries.filter(item => item.acf.sub_category === 'LVT').map((spec => {
                    return spec.acf.resource_category === 'Adhesive' ? <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div> : ''                 
                  }))}
                </div>
              </div>

              <div className="spec-col">
                <div className="resources-col-header">
                  Sealer
                </div>
                <div className="resources-col-links">
                {sundries.filter(item => item.acf.sub_category === 'WPC').map(spec => {
                    return spec.acf.resource_category === 'Sealer' ? <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div> : ''                 
                  })}
                  {sundries.filter(item => item.acf.sub_category === 'LVT').map((spec => {
                    return spec.acf.resource_category === 'Sealer' ? <div key={spec.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={spec.acf.resource_file.url} target="_new">{spec.title.rendered}</a></div> : ''                 
                  }))}
                </div>
              </div>
            </div>
          </div>
        </div>
            
          </div>
          }

          { pageTitle === 'Care & Maintenance' &&
            <div className="resources-wrapper">
            <div className="resources-col-1">
              <div className="resources-col-header">
                Carpet
              </div>
              <div className="resources-col-links">
                {maintenance.filter(item => item.acf.resource_category === 'Carpet').map((man) => (
                  <div key={man.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={man.acf.resource_file.url} target="_new">{man.title.rendered}</a></div>
                ))}
              </div><br/>
              <div className="resources-col-header">
                Entryway System
              </div>
              <div className="resources-col-links">
                {maintenance.filter(item => item.acf.resource_category === 'Entryway').map((man) => (
                  <div key={man.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={man.acf.resource_file.url} target="_new">{man.title.rendered}</a></div>
                ))}
              </div>
            </div>

            <div className="resources-col-2">
              <div className="resources-col-header">
                Hard Surface
              </div>
              <div className="resources-col-links">
                {maintenance.filter(item => item.acf.resource_category === 'Hard Surfaces').map((man) => (
                  <div key={man.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={man.acf.resource_file.url} target="_new">{man.title.rendered}</a></div>
                ))}
              </div>
            </div>
          </div>
          }

          { pageTitle === 'Warranties' &&
            <div className="resources-wrapper">
            <div className="resources-col-1">
              <div className="resources-col-header">
                Carpet
              </div>
              <div className="resources-col-links">
                {warranties.filter(item => item.acf.resource_category === 'Carpet').map((war) => (
                  <div key={war.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={war.acf.resource_file.url} target="_new">{war.title.rendered}</a></div>
                ))}
              </div>
              <br/>
              <div className="resources-col-header">
                Entryway System
              </div>
              <div className="resources-col-links">
                {warranties.filter(item => item.acf.resource_category === 'Entryway').map((war) => (
                  <div key={war.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={war.acf.resource_file.url} target="_new">{war.title.rendered}</a></div>
                ))}
              </div>
            </div>

            <div className="resources-col-2">
              <div className="resources-col-header">
                Hard Surface
              </div>
              <div className="resources-col-links">
                {warranties.filter(item => item.acf.resource_category === 'Hard Surfaces').map((war) => (
                  <div key={war.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={war.acf.resource_file.url} target="_new">{war.title.rendered}</a></div>
                ))}
              </div>
            </div>
          </div>
          }
          
          { pageTitle === 'Antimicrobial' &&
            <div className="resources-wrapper">
            <div className="resources-col-1">
              <div className="resources-col-header">
                Carpet
              </div>
              <div className="resources-col-links">
                {antimicrobial.filter(item => item.acf.resource_category === 'Carpet').map((anti) => (
                  <div key={anti.id} style={{paddingTop: '5px', paddingBottom: '5px'}}><a href={anti.acf.resource_file.url} target="_new">{anti.title.rendered}</a></div>
                ))}
              </div>
            </div>
          </div>
          }
          { pageTitle === 'CADS' &&
          <div className="container">
              
							<div className="row">
							
              <div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58098-B7007-Allure-1405-Charms.pdf" target="_new" rel="noopener noreferrer">Allure – Charms<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58097-B7007-Allure-7604-City-Lights.pdf" target="_new" rel="noopener noreferrer">Allure – City Lights<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58095-B7007-Allure-8202-Magnetism.pdf" target="_new" rel="noopener noreferrer">Allure – Magnetism<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58096-B7007-Allure-8503-Rhythm.pdf" target="_new" rel="noopener noreferrer">Allure – Rhythm<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58100-B7007-Allure-7509-Spice.pdf" target="_new" rel="noopener noreferrer">Allure – Spice<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58099-B7007-Allure-1506-Treasure.pdf" target="_new" rel="noopener noreferrer">Allure – Treasure<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58094-B7007-Allure-4801-Zoot-Suit.pdf" target="_new" rel="noopener noreferrer">Allure – Zoot Suit<br /> </a>
                </div>
              </div>

              <div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58045-B7001-Dash-1405-Charms.pdf" target="_new" rel="noopener noreferrer">Dash – Charms<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58044-B7001-Dash-7604-City-Lights.pdf" target="_new" rel="noopener noreferrer">Dash – City Lights<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58042-B7001-Dash-8202-Magnetism.pdf" target="_new" rel="noopener noreferrer">Dash – Magnetism<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58043-B7001-Dash-8503-Rhythm.pdf" target="_new" rel="noopener noreferrer">Dash – Rhythm<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58047-B7001-Dash-7509-Spice.pdf" target="_new" rel="noopener noreferrer">Dash – Spice<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58046-B7001-Dash-1506-Treasure.pdf" target="_new" rel="noopener noreferrer">Dash – Treasure<br /></a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58041-B7001-Dash-4801-Zoot-Suit.pdf" target="_new" rel="noopener noreferrer">Dash – Zoot Suit<br /></a>
                </div>
              </div>

              <div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58091-B7006-Dazzle-1405-Charms.pdf" target="_new" rel="noopener noreferrer">Dazzle – Charms</a><br /> 
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58090-B7006-Dazzle-7604-City-Lights.pdf" target="_new" rel="noopener noreferrer">Dazzle – City Lights</a><br /> 
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58088-B7006-Dazzle-8202-Magnetism.pdf" target="_new" rel="noopener noreferrer">Dazzle – Magnetism<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58089-B7006-Dazzle-8503-Rhythm.pdf" target="_new" rel="noopener noreferrer">Dazzle – Rhythm<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58093-B7006-Dazzle-7509-Spice.pdf" target="_new" rel="noopener noreferrer">Dazzle – Spice<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58092-B7006-Dazzle-1506-Treasure.pdf" target="_new" rel="noopener noreferrer">Dazzle – Treasure<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58087-B7006-Dazzle-4801-Zoot-Suite.pdf" target="_new" rel="noopener noreferrer">Dazzle – Zoot Suit<br /> </a>
                </div>
              </div>

              <div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58082-B7005-Pizzaz-1405-Charms.pdf" target="_new" rel="noopener noreferrer">Pizazz – Charms<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58081-B7005-Pizzaz-7604-City-Lights.pdf" target="_new" rel="noopener noreferrer">Pizazz – City Lights<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58078-B7005-Pizzaz-8202-Magnetism.pdf" target="_new" rel="noopener noreferrer">Pizazz – Magnetism<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58080-B7005-Pizzaz-8503-Rhythm.pdf" target="_new" rel="noopener noreferrer">Pizazz – Rhythm<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58085-B7005-Pizzaz-7509-Spice.pdf" target="_new" rel="noopener noreferrer">Pizazz – Spice<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58084-B7005-Pizzaz-1506-Treasure.pdf" target="_new" rel="noopener noreferrer">Pizazz – Treasure<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58077-B7005-Pizzaz-4801-Zoot-Suit.pdf" target="_new" rel="noopener noreferrer">Pizazz – Zoot Suit<br /> </a>
                </div>
              </div>
							
            </div>
						
            <div className="row">
						
              <div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58060-B7002-Savor-1405-Charms.pdf" target="_new" rel="noopener noreferrer">Savor – Charms<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58059-B7002-Savor-7604-City-Lights.pdf" target="_new" rel="noopener noreferrer">Savor – City Lights<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58057-B7002-Savor-8202-Magnetism.pdf" target="_new" rel="noopener noreferrer">Savor – Magnetism<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58058-B7002-Savor-8503-Rhythm.pdf" target="_new" rel="noopener noreferrer">Savor – Rhythm<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58075-B7002-Savor-7509-Spice.pdf" target="_new" rel="noopener noreferrer">Savor – Spice<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58074-B7002-Savor-1506-Treasure.pdf" target="_new" rel="noopener noreferrer">Savor – Treasure<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58056-B7002-Savor-4801-Zoot-Suit.pdf" target="_new" rel="noopener noreferrer">Savor – Zoot Suit<br /> </a>
                </div>
              </div>

              <div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57893-B7003-Vibe-1405-Charms.pdf" target="_new" rel="noopener noreferrer">Vibe – Charms<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57892-B7003-Vibe-7604-City-Lights.pdf" target="_new" rel="noopener noreferrer">Vibe – City Lights<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57890-B7003-Vibe-8202-Magnetism.pdf" target="_new" rel="noopener noreferrer">Vibe – Magnetism<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57891-B7003-Vibe-8503-Rhythm.pdf" target="_new" rel="noopener noreferrer">Vibe – Rhythm<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57895-B7003-Vibe-7509-Spice.pdf" target="_new" rel="noopener noreferrer">Vibe – Spice<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57894-B7003-Vibe-1506-Treasure.pdff" target="_new" rel="noopener noreferrer">Vibe – Treasure<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I58041-B7001-Vibe-4801-Zoot-Suit.pdf" target="_new" rel="noopener noreferrer">Vibe – Zoot Suit<br /> </a>
                </div>
              </div>

              <div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57883-B7004-Zeal-1405-Charms.pdf" target="_new" rel="noopener noreferrer">Zeal – Charms<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57882-B7004-Zeal-7604-City-Lights.pdf" target="_new" rel="noopener noreferrer">Zeal – City Lights<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57880-B7004-Zeal-8202-Magnetism.pdf" target="_new" rel="noopener noreferrer">Zeal – Magnetism<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57881-B7004-Zeal-8503-Rhythm.pdf" target="_new" rel="noopener noreferrer">Zeal – Rhythm<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57885-B7004-Zeal-7509-Spice.pdf" target="_new" rel="noopener noreferrer">Zeal – Spice<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57884-B7004-Zeal-1506-Treasure.pdf" target="_new" rel="noopener noreferrer">Zeal – Treasure<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2019/05/I57878-B7004-Zeal-4801-Zoot-Suit.pdf" target="_new" rel="noopener noreferrer">Zeal – Zoot Suit<br /> </a>
                </div>
              </div>
							
							<div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7035-Wick_1302-Arlo.pdf" target="_new" rel="noopener noreferrer">Wick – Arlo<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7035-Wick_2206-Zephyr.pdf" target="_new" rel="noopener noreferrer">Wick – Zephyr<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7035-Wick_4501-Hudson.pdf" target="_new" rel="noopener noreferrer">Wick – Hudson<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7035-Wick_4907-Maverick.pdf" target="_new" rel="noopener noreferrer">Wick – Maverick<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7035-Wick_5203-Ezra.pdf" target="_new" rel="noopener noreferrer">Wick – Ezra<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7035-Wick_7404-Harper.pdf" target="_new" rel="noopener noreferrer">Wick – Harper<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7035-Wick_8305-Asher.pdf" target="_new" rel="noopener noreferrer">Wick – Asher<br /> </a>
                </div>
              </div>
							
            </div>						
						
						<div className="row">						
					
							<div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7036-Traven_1302-Arlo.pdf" target="_new" rel="noopener noreferrer">Traven – Arlo<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7036-Traven_2206-Zephyr.pdf" target="_new" rel="noopener noreferrer">Traven – Zephyr<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7036-Traven_4501-Hudson.pdf" target="_new" rel="noopener noreferrer">Traven – Hudson<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7036-Traven_4907-Maverick.pdf" target="_new" rel="noopener noreferrer">Traven – Maverick<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7036-Traven_5203-Ezra.pdf" target="_new" rel="noopener noreferrer">Traven – Ezra<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7036-Traven_7404-Harper.pdf" target="_new" rel="noopener noreferrer">Traven – Harper<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7036-Traven_8305-Asher.pdf" target="_new" rel="noopener noreferrer">Traven – Asher<br /> </a>
                </div>
              </div>
							
							<div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7037-Falco_1302-Arlo.pdf" target="_new" rel="noopener noreferrer">Falco – Arlo<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7037-Falco_2206-Zephyr.pdf" target="_new" rel="noopener noreferrer">Falco – Zephyr<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7037-Falco_4501-Hudson.pdf" target="_new" rel="noopener noreferrer">Falco – Hudson<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7037-Falco_4907-Maverick.pdf" target="_new" rel="noopener noreferrer">Falco – Maverick<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7037-Falco_5203-Ezra.pdf" target="_new" rel="noopener noreferrer">Falco – Ezra<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7037-Falco_7404-Harper.pdf" target="_new" rel="noopener noreferrer">Falco – Harper<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7037-Falco_8305-Asher.pdf" target="_new" rel="noopener noreferrer">Falco – Asher<br /> </a>
                </div>
              </div>
							
							<div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7038-Wick_1302-Arlo.pdf" target="_new" rel="noopener noreferrer">Lomax – Arlo<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7038-Lomax_2206-Zephyr.pdf" target="_new" rel="noopener noreferrer">Lomax – Zephyr<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7038-Lomax_4501-Hudson.pdf" target="_new" rel="noopener noreferrer">Lomax – Hudson<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7038-Lomax_4907-Maverick.pdf" target="_new" rel="noopener noreferrer">Lomax – Maverick<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7038-Lomax_5203-Ezra.pdf" target="_new" rel="noopener noreferrer">Lomax – Ezra<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7038-Lomax_7404-Harper.pdf" target="_new" rel="noopener noreferrer">Lomax – Harper<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7038-Lomax_8305-Asher.pdf" target="_new" rel="noopener noreferrer">Lomax – Asher<br /> </a>
                </div>
              </div>							
														
							<div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7039-Kai_1302-Arlo.pdf" target="_new" rel="noopener noreferrer">Kai – Arlo<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7039-Kai_2206-Zephyr.pdf" target="_new" rel="noopener noreferrer">Kai – Zephyr<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7039-Kai_4501-Hudson.pdf" target="_new" rel="noopener noreferrer">Kai – Hudson<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7039-Kai_4907-Maverick.pdf" target="_new" rel="noopener noreferrer">Kai – Maverick<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7039-Kai_5203-Ezra.pdf" target="_new" rel="noopener noreferrer">Kai – Ezra<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7039-Kai_7404-Harper.pdf" target="_new" rel="noopener noreferrer">Kai – Harper<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7039-Kai_8305-Asher.pdf" target="_new" rel="noopener noreferrer">Kai – Asher<br /> </a>
                </div>
              </div>
							
            </div>
						
					<div className="row">						
					
							<div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7040-Utah_1302-Arlo.pdf" target="_new" rel="noopener noreferrer">Utah – Arlo<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7040-Utah_2206-Zephyr.pdf" target="_new" rel="noopener noreferrer">Utah – Zephyr<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7040-Utah_4501-Hudson.pdf" target="_new" rel="noopener noreferrer">Utah – Hudson<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7040-Utah_4907-Maverick.pdf" target="_new" rel="noopener noreferrer">Utah – Maverick<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7040-Utah_5203-Ezra.pdf" target="_new" rel="noopener noreferrer">Utah – Ezra<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7040-Utah_7404-Harper.pdf" target="_new" rel="noopener noreferrer">Utah – Harper<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7040-Utah_8305-Asher.pdf" target="_new" rel="noopener noreferrer">Utah – Asher<br /> </a>
                </div>
              </div>
							
							<div className="col-md-3 pb-3">
                <div className="resources-col-links">
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7041-Neo_1302-Arlo.pdf" target="_new" rel="noopener noreferrer">Neo – Arlo<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7041-Neo_2206-Zephyr.pdf" target="_new" rel="noopener noreferrer">Neo – Zephyr<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7041-Neo_4501-Hudson.pdf" target="_new" rel="noopener noreferrer">Neo – Hudson<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7041-Neo_4907-Maverick.pdf" target="_new" rel="noopener noreferrer">Neo – Maverick<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7041-Neo_5203-Ezra.pdf" target="_new" rel="noopener noreferrer">Neo – Ezra<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7041-Neo_7404-Harper.pdf" target="_new" rel="noopener noreferrer">Neo – Harper<br /> </a>
                  <a href="https://www.signatureflooring.com/wp-content/uploads/2023/02/B7041-Neo_8305-Asher.pdf" target="_new" rel="noopener noreferrer">Neo – Asher<br /> </a>
                </div>
              </div>
							
            </div>
						
          </div>					

        }
        </div>

        {/** NEED HELP? */}
        <div className="resources-need-help">
          <div className="resources-need-help-text">
              <h2>Need Help?</h2> 
              <p style={{textAlign: 'center'}}>For more information or technical resources, please contact your sales representative or call us at 800-809-7086.</p>
              <a href="/salesreps" className="resources-find-rep-button">Find Your Signature Rep</a>&nbsp;&nbsp;
            </div>
        </div>
      </div>
    )
  }
}

export default Resources
